import { AlgoDataType } from '@core/models/algo';
import { ExtraWidgetSettings } from '@core/models/widget';

type SettingsDataTypeMap = Record<
  AlgoDataType | 'DEFAULT',
  (keyof ExtraWidgetSettings)[]
>;
/**
 * Declares settings related only to 1 data type. Default settings described in
 * AppConfig
 */
export const WIDGET_EXTRA_SETTINGS_DATA_TYPE_MAP: SettingsDataTypeMap = {
  EstimatePairDataView: ['color1', 'color2'],
  BipolarEstimatePairDataView: ['color1', 'color2'],
  EstimateDataView: ['color1', 'color2', 'relativeValue'],
  DEFAULT: ['color1', 'color2'],
};
